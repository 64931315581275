import React, {useEffect} from 'react';
import InputUrl from "../components/InputUrl";
import ChallengeBottom from "../components/ChallengeBottom";
import SoundButton from "../components/SoundButton";
import Logos from "../components/Logos";
import EnergyBlock from "../components/EnergyBlock";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentGame} from "../store/mainReducer";

const Water = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const currentGame = useSelector(state=>state.main.currentGame)

    useEffect(()=>{
        if (user.water_state===1) {
            dispatch(setCurrentGame(1))
        } else if (user.water_state===2) {
            dispatch(setCurrentGame(2))
        }
    },[user])

    let content = []
    if (currentGame === 1) {
        content.push(<span>Убить снежком в ARAM</span>)
    } else if (currentGame === 2) {
        content.push(<span>Украсть водного дракона</span>)
    }
    return (
        <div className="container main-screen water-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block">
                    <p className="middle-block__page-name">
                        Вода
                    </p>
                    <EnergyBlock/>
                </div>
                <div className="challenge-block main-screen__challenge-block">
                    <div className="challenge-block__info">
                        <div className="challenge-block__description">
                            <p>Твой челлендж</p>
                            {content}
                        </div>
                        <ul className="challenge-list">
                            <li>Запиши видео с выполнением челленджа в Wild Rift</li>
                            <li>Опубликуй видео на своей странице VK</li>
                            <li>Поделись ссылкой на запись в специальном поле ниже</li>
                        </ul>
                    </div>
                    <InputUrl/>
                    <ChallengeBottom/>
                </div>
            </div>
        </div>
    );
};

export default Water;