import React from 'react';
import Logos from "../components/Logos";
import SoundButton from "../components/SoundButton";
import EnergyBlock from "../components/EnergyBlock";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Dashboard = () => {
    const dispatch = useDispatch()
    return (
        <div className="container main-screen start-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block main-screen__middle-block">
                    <p className="text middle-block__text">Выполняй челленджи в Wild Rift, делись видео и борись <nobr> за
                        образы </nobr> до 990 WC, а еще не пропусти суперигру и шанс выиграть смартфон Samsung Galaxy S21</p>
                    <EnergyBlock/>
                </div>
                <div className="games-menu main-screen__games-menu">
                    <button onClick={()=>dispatch(setActivePanel('water'))} className="game-item game-item_type_water">
                        <p className="game-item__caption">вода</p>
                    </button>
                    <button onClick={()=>dispatch(setActivePanel('stone'))} className="game-item game-item_type_stone">
                        <p className="game-item__caption">камень</p>
                    </button>
                    <button onClick={()=>dispatch(setActivePanel('fire'))} className="game-item game-item_type_fire">
                        <p className="game-item__caption">огонь</p>
                    </button>
                    <button onClick={()=>dispatch(setActivePanel('air'))} className="game-item game-item_type_air">
                        <p className="game-item__caption">воздух</p>
                    </button>
                </div>
                <div className="start-screen__buttons">
                    <div className="start-screen__buttons-line">
                        <button onClick={()=>dispatch(setActivePanel('supergame'))} className="button button_type_first">суперигра</button>
                        <button onClick={()=>dispatch(setActivePanel('broadcast'))} className="button button_type_first">Трансляция</button>
                    </div>
                    <button onClick={()=>dispatch(setActivePanel('info'))} className="button button_type_second">Как получить энергию</button>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;