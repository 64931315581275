import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setMusic} from "../store/mainReducer";

const SoundButton = () => {
    const dispatch = useDispatch()
    const onMusic = useSelector(state => state.main.music)
    let img = (onMusic) ? 'on' : 'off'
    function music () {
        dispatch(setMusic(!onMusic))
    }
    return (
        <>
            <button onClick={music} className="header__sound-switch">
                <img src={`images/sound-${img}.svg`} alt=""/>
            </button>
        </>
    );
};

export default SoundButton;