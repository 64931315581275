import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeAirState,
    changeFireState,
    changeStoneState,
    changeWaterState,
    decrementEnergy,
    setActivePanel,
} from "../store/mainReducer";
import ServerConnect from "../service";

const ChallengeBottom = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const currentGame = useSelector(state=>state.main.currentGame)
    let isDisabled = (user.energy <= 0) ? [true, 'button_disabled'] : [false, '']

    function change_challenge () {
        let context;
        if (currentGame === 1 || currentGame === 2) {
            if (user.water_state === 1) {
                dispatch(changeWaterState(2))
                context = {'water_state': 2}
            } else {
                dispatch(changeWaterState(1))
                context = {'water_state': 1}
            }
        } else if (currentGame === 3 || currentGame === 4) {
            if (user.stone_state === 1) {
                dispatch(changeStoneState(2))
                context = {'stone_state': 2}
            } else {
                dispatch(changeStoneState(1))
                context = {'stone_state': 1}
            }
        } else if (currentGame === 5 || currentGame === 6) {
            if (user.fire_state === 1) {
                dispatch(changeFireState(2))
                context = {'fire_state': 2}
            } else {
                dispatch(changeFireState(1))
                context = {'fire_state': 1}
            }
        } else if (currentGame === 7 || currentGame === 8) {
            if (user.air_state === 1) {
                dispatch(changeAirState(2))
                context = {'air_state': 2}
            } else {
                dispatch(changeAirState(1))
                context = {'air_state': 1}
            }
        }
        dispatch(decrementEnergy())
        context['energy'] = user.energy - 1
        ServerConnect.sendPut('/api/user/'+ user.vk_id, context)
    }

    return (
        <div className="challenge-block__bottom">
            <button onClick={()=>dispatch(setActivePanel('menu'))} className="challenge-block__back-link back-link">
               &#60;назад
            </button>
            <div className="challenge-block__attention">
                <p>На перевыбор челленджа или отправку видео </p>
                <p><span>будет потрачена 1 </span><img src="images/energy-icon.svg" alt=""/></p>
            </div>
            <button disabled={isDisabled[0]}
                    onClick={change_challenge}
                    className={`button button_type_second ${isDisabled[1]}`}>Выбрать другой челлендж</button>
        </div>
    );
};

export default ChallengeBottom;