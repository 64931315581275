import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setUser} from "./store/mainReducer";
import ServerConnect from "./service";
import Init from "./panels/Init";
import Dashboard from "./panels/Dashboard";
import SuperGame from "./panels/SuperGame";
import BroadCast from "./panels/BroadCast";
import Info from "./panels/Info";
import Water from "./panels/Water";
import Stone from "./panels/Stone";
import Fire from "./panels/Fire";
import Air from "./panels/Air";

const App = () => {

  const dispatch = useDispatch()
  const mus = document.querySelector("#bg_sound")
  const activePanel = useSelector(state => state.main.activePanel)
  const music = useSelector(state=>state.main.music)

  useEffect(()=>{
    mus.disableRemotePlayback = true;
    if (music) {
      mus.play()
    } else {
      mus.pause()
    }
  },[music])

  useEffect(() => {
    async function fetchData() {
      const user = await bridge.send('VKWebAppGetUserInfo');
      if (user) {
        return user;
      }
    }

    fetchData().then(data => get_user_info(data))

  }, [])

  async function get_user_info(user) {
    let response = await fetch(ServerConnect.api_url + '/api/user/' + user.id);
    if (response.status === 200) {
      let user_info = await response.json()
      dispatch(setUser(user_info))
    }
  }

  let content = []
  if (activePanel === 'init') {
    content.push(<Init/>)
  } else if (activePanel === 'menu') {
    content.push(<Dashboard/>)
  } else if (activePanel === 'supergame') {
    content.push(<SuperGame/>)
  } else if (activePanel === 'broadcast') {
    content.push(<BroadCast/>)
  } else if (activePanel === 'info') {
    content.push(<Info/>)
  } else if (activePanel === 'water') {
    content.push(<Water/>)
  } else if (activePanel === 'stone') {
    content.push(<Stone/>)
  } else if (activePanel === 'fire') {
    content.push(<Fire/>)
  } else if (activePanel === 'air') {
    content.push(<Air/>)
  }

  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;
