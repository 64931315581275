import React from 'react';
import SoundButton from "../components/SoundButton";
import Logos from "../components/Logos";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Info = () => {
    const dispatch = useDispatch()
    return (
        <div className="container main-screen energy-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block">
                    <p className="middle-block__page-name energy-page__page-name">Как получить энергию</p>
                </div>
                <ul className="energy-list energy-page__energy-list">
                    <li>Ставь лайки на постах в сообществе, опубликованных после 23 ноября</li>
                    <li>За каждый лайк ты получишь дополнительную энергию</li>
                    <li>Используй энергию, чтобы выбирать другие челленджи
                        и отправлять ссылки на выполненные
                    </li>
                </ul>
                <div className="energy-page__bottom">
                    <button onClick={()=>dispatch(setActivePanel('menu'))} className="back-link">
                       &#60;назад
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Info;