import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setMusic} from "../store/mainReducer";

const Init = () => {
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(true)
    let isDisabled = (isActive) ? [true, 'button_disabled'] : [false, '']
    let opacBtn = (isActive) ? '0%' : '100%'
    let opacProgress = (isActive) ? '100%' : '0%'

    useEffect(()=>{
        setTimeout(()=> {
            setIsActive(false)
        },3000)
    },[])

    function start () {
        dispatch(setMusic(true))
        dispatch(setActivePanel('supergame'))
    }

    return (
        <div className="container start-screen">
            <div className="container__content start-screen__content">
                <div className="intro-block start-screen__intro-block">
                    <div className="intro-block__logos logos">
                        <div className="logos__lol-logo">
                            <img width="64" height="43" src="images/lol-logo.png" alt=""/>
                        </div>
                        <div className="logos__samsung-logo">
                            <img width="90" height="14" src="images/samsung-logo.png" alt=""/>
                        </div>
                        <div className="logos__play-logo">
                            <img width="149" height="22" src="images/tagline.png" alt=""/>
                        </div>
                    </div>
                    <div style={{opacity:opacProgress}} className="progerss-bar intro-block__progress-bar">
                        <p className="progerss-bar__caption">загрузка</p>
                        <div className="progress-bar__items">
                            <div className="progerss-bar__item"></div>
                            <div className="progerss-bar__item"></div>
                            <div className="progerss-bar__item"></div>
                            <div className="progerss-bar__item"></div>
                            <div className="progerss-bar__item"></div>
                        </div>
                    </div>
                    <button disabled={isDisabled[0]}
                            onClick={start}
                            style={{opacity: opacBtn}}
                            className={`start-screen__button button button_type_first ${isDisabled[1]}`}>начать</button>
                </div>
            </div>
        </div>
    );
};

export default Init;