import React from 'react';
import SoundButton from "../components/SoundButton";
import Logos from "../components/Logos";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const BroadCast = () => {
    const dispatch = useDispatch()
    return (
        <div className="container main-screen video-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block">
                    <p style={{fontSize:"35px"}} className="middle-block__page-name">Экстрим-стрим<br className="br-xs"/> #СыграйНаS21</p>
                </div>
                <div className="video-block main-screen__video-block">
                    <div className="video-block__content">
                        <p className="text video-block__text video-block__text_visible_xs">Смотри крутое
                                шоу с игроками из Unicorns of Love</p>
                        <div className="video-block__frame">
                            <iframe
                                src="https://vk.com/video_ext.php?oid=-187455013&id=456244067&hash=d27111d3e63c05f4&hd=2"
                                width="100%"
                                allow="autoplay; encrypted-media; picture-in-picture;"
                                frameBorder="0"></iframe>
                        </div>
                        <div className="video-block__info">
                            <p className="text video-block__text video-block__text_visible_lg">Смотри крутое
                                шоу с игроками из Unicorns of Love</p>
                            <a href='https://vk.com/video-187455013_456244067' target="_blank" className="button button_type_first">смотреть</a>
                        </div>
                    </div>
                    <div className="video-page__bottom">
                        <button onClick={()=>dispatch(setActivePanel('menu'))} className="button button_type_second">Перейти к челленджам</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BroadCast;