import React from 'react';

const Logos = () => {
    return (
        <div className="header__logos logos">
            <div className="logos__lol-logo">
                <img width="64" height="43" src="images/lol-logo.png" alt=""/>
            </div>
            <div className="logos__samsung-logo">
                <img width="90" height="14" src="images/samsung-logo.png" alt=""/>
            </div>
            <div className="logos__play-logo">
                <img width="149" height="22" src="images/tagline.png" alt=""/>
            </div>
        </div>
    );
};

export default Logos;