import React, {useEffect} from 'react';
import SoundButton from "../components/SoundButton";
import Logos from "../components/Logos";
import EnergyBlock from "../components/EnergyBlock";
import InputUrl from "../components/InputUrl";
import ChallengeBottom from "../components/ChallengeBottom";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentGame} from "../store/mainReducer";

const Air = () => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.main.user)
    const currentGame = useSelector(state => state.main.currentGame)

    useEffect(() => {
        if (user.air_state === 1) {
            dispatch(setCurrentGame(7))
        } else if (user.air_state === 2) {
            dispatch(setCurrentGame(8))
        }
    }, [user])

    let content = []
    if (currentGame === 7) {
        content.push(<span>Победить в матче менее, чем&nbsp;за 12 минут</span>)
    } else if (currentGame === 8) {
        content.push(<span>Украсть воздушного дракона</span>)
    }
    return (
        <div className="container main-screen air-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block">
                    <p className="middle-block__page-name">воздух</p>
                    <EnergyBlock/>
                </div>
                <div className="challenge-block main-screen__challenge-block">
                    <div className="challenge-block__info">
                        <div className="challenge-block__description">
                            <p>Твой челлендж</p>
                            {content}
                        </div>
                        <ul className="challenge-list">
                            <li>Запиши видео с выполнением челленджа в Wild Rift</li>
                            <li>Опубликуй видео на своей странице VK</li>
                            <li>Поделись ссылкой на запись в специальном поле ниже</li>
                        </ul>
                    </div>
                    <InputUrl/>
                    <ChallengeBottom/>
                </div>
            </div>
        </div>
    );
};

export default Air;