import React, {useState} from 'react';
import SoundButton from "../components/SoundButton";
import Logos from "../components/Logos";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer";
import ServerConnect from "../service";

const SuperGame = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const [gameUrl, setGameUrl] = useState('')
    let isDisabled = (gameUrl === '') ? [true, 'button_disabled'] : [false, '']

    function sendRes () {
        setGameUrl('')
        ServerConnect.sendPut('/api/user/challenge/'+ user.vk_id, {'super_game': gameUrl})
    }

    return (
        <div className="container main-screen superplay-page">
            <div className="container__content">
                <div className="header main-screen__header">
                    <Logos/>
                    <SoundButton/>
                </div>
                <div className="middle-block">
                    <p className="middle-block__page-name">СУПЕРИГРА</p>
                </div>
                <div className="challenge-block main-screen__challenge-block">
                    <div className="challenge-block__super-play-info">
                        <p className="text challenge-block__super-description">Поделись своими лучшими результатами
                            матча и поборись за главный приз — смартфон Samsung Galaxy S21!</p>
                        <ul className="challenge-list superplay-page__challange-list">
                            <li>Сделай скриншот своего крутого результата в послематчевом лобби</li>
                            <li>Опубликуй скриншот у себя на стене VK с хэштегом #РезультатНаS21</li>
                            <li>Отправь ссылку на запись в специальное поле ниже</li>
                            <li>Под записью с конкурсом отметь друга, которому хочешь бросить вызов</li>
                        </ul>
                        <a href="#" className="superplay-page__about-link-xs">Подробнее о суперигре</a>
                    </div>
                    <div className="challenge-block__form">
                        <input
                            type="text"
                            className="input challenge-block__input"
                            placeholder="Введи ссылку"
                            value = {gameUrl}
                            onChange={(e)=>setGameUrl(e.target.value)}
                        />
                            <button
                                disabled={isDisabled[0]}
                                onClick={sendRes}
                                className={`button button_type_first ${isDisabled[1]}`}>отправить</button>
                    </div>
                    <div className="challenge-block__bottom">
                        <a href='https://vk.com/wall-187455013_296592' target="_blank" className="challenge-block__back-link back-link superplay-page__about-link">Подробнее о
                            суперигре
                        </a>
                        <button onClick={()=>dispatch(setActivePanel('menu'))} className="button button_type_second button_position_center ">участвовать в челленджах</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuperGame;