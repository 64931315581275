import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        activePanel: 'init',
        user: null,
        music: false,
        currentGame: 1
    },
    reducers:{
        setActivePanel(state, action) {
               state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        decrementEnergy(state) {
            if (state.user.energy <= 0) {
                state.user.energy = 0
            } else {
                state.user.energy = state.user.energy - 1
            }
        },
        changeWaterState(state,action) {
            state.user.water_state = action.payload
        },
        changeAirState(state,action) {
            state.user.air_state = action.payload
        },
        changeFireState(state,action) {
            state.user.fire_state = action.payload
        },
        changeStoneState(state,action) {
            state.user.stone_state = action.payload
        },
        setMusic(state,action){
            state.music = action.payload
        },
        setCurrentGame(state,action){
            state.currentGame = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel,setUser, setMusic, setCurrentGame,
    decrementEnergy, changeAirState,changeWaterState, changeFireState,changeStoneState
} = mainSlice.actions
