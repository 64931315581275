import React, {useState} from 'react';
import ServerConnect from "../service";
import {useDispatch, useSelector} from "react-redux";
import {decrementEnergy} from "../store/mainReducer";

const InputUrl = () => {
    const dispatch = useDispatch()
    const [val, setVal] = useState('')
    const user = useSelector(state=>state.main.user)
    const currentGame = useSelector(state=>state.main.currentGame)
    let isDisabled = (val === '' || user.energy <= 0) ? [true, 'button_disabled'] : [false, '']

    function sendUrl () {
        let state;
        if (currentGame === 1) {
            state = 'water_1'
        } else if (currentGame === 2) {
            state = 'water_2'
        } else if (currentGame === 3) {
            state = 'stone_1'
        } else if (currentGame === 4) {
            state = 'stone_2'
        } else if (currentGame === 5) {
            state = 'fire_1'
        } else if (currentGame === 6) {
            state = 'fire_2'
        } else if (currentGame === 7) {
            state = 'air_1'
        } else if (currentGame === 8) {
            state = 'air_2'
        }
        setVal('')
        dispatch(decrementEnergy())
        let context = {[state]:val}
        context['energy'] = user.energy -1
        ServerConnect.sendPut('/api/user/challenge/' + user.vk_id, context)
    }

    return (
        <div className="challenge-block__form">
            <input
                type="text"
                className="input challenge-block__input"
                placeholder="Введи ссылку"
                value={val}
                onChange={(e) => setVal(e.target.value)}
            />
                <button
                    onClick={sendUrl}
                    disabled={isDisabled[0]}
                    className={`button button_type_first ${isDisabled[1]}`}>отправить</button>
        </div>
    );
};

export default InputUrl;