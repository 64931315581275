import React from 'react';
import {useSelector} from "react-redux";

const EnergyBlock = () => {
    const user = useSelector(state=>state.main.user)
    return (
        <div className="energy-block">
            <p className="energy-block__text">Количество энергии</p>
            <div className="energy-block__value">
                <img src="images/energy-icon.svg" alt=""/>
                    <p>{user.energy}</p>
            </div>
        </div>
    );
};

export default EnergyBlock;