const api_url = 'https://samsung.techbot24.ru'
const params = window.location.search;

async function postResource(url = '', data = {}) {
    let response = await fetch(
        api_url + url + params,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}

async function putResource(url = '', data = {}) {
    let response = await fetch(
        api_url + url + params,
        {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response) {
        throw new Error(`Неполучилось отправить PUT запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}

async function getResource(url){

    let response = await fetch(api_url+ url );
    if (!response) {
        throw new Error(`Не получилось получить данные ${url}, статус ${response.status}`)
    }
    return await response.json()
}



const ServerConnect ={
    sendPost: postResource,
    sendGet: getResource,
    sendPut: putResource,
    api_url: api_url
}

export default ServerConnect;
